import routerOptions0 from "/home/runner/work/flagman/flagman/apps/frontstore/node_modules/.pnpm/nuxt@3.16.2_@parcel+watcher@2.5.1_@types+node@22.14.0_db0@0.3.1_eslint@9.24.0_jiti@2.4._0bcfd39a16c7773726dbc85ef80643ae/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/runner/work/flagman/flagman/apps/frontstore/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export const hashMode = routerOptions0.hashMode ?? false
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}